<!-- 编辑任务工单类型 -->
<template>
  <div v-loading="loading" class="container">
    <div class="header">
      <span class="header-tit">编辑任务工单类型</span>
      <div class="hebtns">
        <el-button @click="() => { $router.go(-1); } " class="common-screen-btn btn">返回</el-button>
        <el-button @click="editSaveWork();" type="primary" class="common-screen-btn btn">保存</el-button>
      </div>
    </div>
    <div class="line"></div>
    <div class="body">
      <div class="calname">
        <span>类型名称</span>
        <el-input
          v-model="params.workTypeName"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
          show-word-limit
        ></el-input>
      </div>
      <div style="display: flex;">
        <span>类型描述</span>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="params.typeDescribe"
          maxlength="200"
          show-word-limit
          class="common-screen-input"
        >
        </el-input>
      </div>
    </div>
    <!-- 流程 -->
    <div class="pro-container">
      <span class="set">流程设置</span>
      <div class="process">
          <el-steps direction="vertical" align-center>
            <el-step title="创建工单"></el-step>
            <!-- 数组遍历 -->
            <el-step v-for="(item,i) in params.workOrderFlowDTOList" :key="i" class="everystep">
              <template #icon>
                {{2+i}}
              </template>
              <template #title>
                <div class="box">
                  <span class="black">任务名称</span>
                  <span class="white"
                    ><el-input
                      v-model="item.taskName"
                      placeholder="请输入内容"
                      maxlength="16"
                      show-word-limit
                    ></el-input
                  ></span>
                </div>
                <div class="btn-group">
                  <el-button @click="() => { sortUp(item,i) }" icon="el-icon-top" plain>上移</el-button>
                  <el-button @click="() => { sortDown(item,i) }" icon="el-icon-bottom" plain class="down">下移</el-button>
                  <el-popconfirm
                    v-if="i != 0"
                    title="确定删除吗？"
                    @confirm="() => { deleteStep(item,i) }"
                  >
                  <el-button type="primary" icon="el-icon-delete" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </template>
              <template #description>
                <div class="des">
                  <div class="desTit">任务描述</div>
                  <div class="desCon">
                    <el-input
                      type="textarea"
                      placeholder="请输入"
                      v-model="item.taskDescribe"
                      maxlength="500"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                </div>
                <div class="upload-container">
                  <div class="upload-top">
                    <div class="tip">
                      <div class="uploadBtn-container">
                        添加附件<input
                          @input="
                            (e) => {
                              uploadFile(e,i);
                            }
                          "
                          class="uploadBtn-input"
                          type="file"
                        />
                      </div>
                      上传图片及文件，大小不超过50MB
                    </div>
                  </div>
                  <div
                    v-if="fileObj[i] && fileObj[i].length"
                    class="fileList-container"
                  >
                    <div
                      v-for="(item2, index) in fileObj[i]"
                      :key="index"
                      class="fileItem"
                    >
                      <div class="fileItem-name">
                        <common-picture
                          :fileUrl="item2.url"
                          :fileType="
                            Config.fileType.imgType.includes(
                              item2.url.split('.')[
                                item2.url.split('.').length - 1
                              ]
                            )
                              ? 'imgText'
                              : 'file'
                          "
                          :fileName="item2.name"
                        ></common-picture>
                      </div>
                      <div class="fileItem-btn-Container">
                        <div
                          class="fileItemBtn"
                          @click="
                            () => {
                              download(item2.url);
                            }"
                        >
                          下载
                        </div>
                        <el-button @click="deleteFile(item2.id,i,index)" type="text" size="small"
                          >删除</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="handler">
                  <div class="box">
                    <span class="black">处理人</span>
                    <div class="person">
                      <!-- 选中数组 -->
                      <div>
                        <span v-for="(item3,ii) in hanObj[i]" :key=ii class="ever">{{item3.name}}</span>
                      </div>
                      <el-button @click="() => { handlerVisible = true;armNum = i }" type="text" class="btn">选择</el-button>
                    </div>
                  </div>
                </div>
              </template>
            </el-step>
            <!-- 数组遍历 end-->
            <!-- 新增按钮 -->
            <el-step class="add">
              <template #icon>
                <el-button
                  @click="
                    () => {
                      addList();
                    }
                  "
                  icon="el-icon-circle-plus-outline"
                  circle
                ></el-button>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                {{ 1 + params.workOrderFlowDTOList.length + 1 }}
              </template>
              <template #title>
                <div class="box">
                  <span class="black">抄送人</span>
                  <div class="person">
                    <!-- 选中数组 -->
                    <div>
                      <span v-for="(item,i) in sendList" :key=i class="ever">{{item.name}}</span>
                    </div>
                    <el-button @click="() => { handlerVisible = true;type = 'one' }" type="text" class="btn">选择</el-button>
                  </div>
                </div>
              </template>
            </el-step>
            <el-step>
              <template #icon>
                {{ 1 + params.workOrderFlowDTOList.length + 2 }}
              </template>
              <template #title>
                <div class="box">
                  <span class="black">负责人</span>
                  <div class="person">
                    <!-- 选中数组 -->
                    <div>
                      <span v-for="(item,i) in headList" :key=i class="ever">{{item.name}}</span>
                    </div>
                    <el-button @click="() => { handlerVisible = true;type = 'two' }" type="text" class="btn">选择</el-button>
                  </div>
                </div>
              </template>
            </el-step>
            <el-step title="关闭工单">
              <template #icon>
                {{ 1 + params.workOrderFlowDTOList.length + 3 }}
              </template>
            </el-step>
          </el-steps>
      </div>
    </div>

    <!-- 设置处理人弹 -->
    <el-dialog 
      v-dialogDrag 
      title="设置处理人" 
      :append-to-body="true" 
      :visible.sync="handlerVisible" 
      width="630px" 
      :before-close="() => { handlerVisible = false;radio = 1;type = ''; }" 
      :close-on-click-modal="false">
      <div>
        <el-radio-group v-model="radio">
          <el-radio :label="1">角色</el-radio>
          <el-radio :label="2">发起人自选</el-radio>
          <el-radio v-if="isUseCompany != '1'" :label="3">指定人员</el-radio>
        </el-radio-group>
        <br/>
        <br/>
        <el-button v-if="radio == 1" @click="() => { chooseRoles() }" size="mini" class="chrole" icon="el-icon-plus">选择角色</el-button>
        <el-button
          v-if="radio == 3"
          @click="
            () => {
              choosePeople();
            }
          "
          size="mini"
          class="chrole"
          icon="el-icon-plus"
          >选择人员</el-button
        >
      </div>
      <span v-if="radio==2" slot="footer" class="dialog-footer">
          <el-button class="common-screen-btn" @click="() => { handlerVisible = false;radio = 1;type = ''; }">取 消</el-button>
          <el-button class="common-screen-btn" type="primary" @click="() => { colletRoleId(); handlerVisible = false;radio = 1;}">确 定</el-button>
      </span>
    </el-dialog>

    <choose-role ref="choRole" :isPeople="isPeople" :oneRole="oneRole" :personVisible="roleVisible" :showRoleId="showRoleId" @roleClose="roleClose" @colletRoleId="colletRoleId"></choose-role>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import CommonPicture from "../../components/common/commonPicture.vue";
import { Config } from "../../utils/index.js";
import { selectWorkOrder,updateWorkOrder,getTemplateRole } from "../../service/workOrder.js";
import { commonAllRole } from "../../service/common.js"
import chooseRole from "./children/chooseRole.vue"
import { upload } from "../../service/upload.js";
import { mapState } from "vuex";

export default {
  name: "",
  components: { CommonPicture,chooseRole },
  props: {},
  data() {
    return {
      Config,
      loading: false,
      params:{
        id: this.$route.query.id,
        workTypeName: "",
        typeDescribe: "",
        workOrderFlowDTOList:[
          // {taskName: '',taskDescribe: '',fileIds: [],fileList:[],approveType: '',approveId: '',sort: ''}
        ]
      },
      handlerVisible: false, //设置处理人弹
      roleVisible: false,//选择角色
      radio: 1,
      ///////流程/////////////
      armNum: null,//判断哪个流程选择人员
      hanObj: {},//用于展示处理人遍历数据
      fileList: [],//用于存上传文件
      fileObj: {},//用于展示上传文件遍历数据
      //////////////
      type: '', //抄送人||负责人
      sendList: [], //抄送人
      headList: [], //负责人
      roleDataList: [],//获取角色列表
      roleInfoList: [],//角色id和name
      // roleData: [] //根据返回角色id查到对应name列表
      showRoleId:"", //回显到选择角色
      oneRole: "",
      isPeople: false,
      isUseCompany: ""
    };
  },
  //监听属性 类似于data概念
  computed: {
        ...mapState(["getUserInfo","comId"])
    },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId()
    // this.getDetails()
    this.commonAllRole()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
        this.getDetails()
        }
      },400)
    },
    async commonAllRole () { // 角色列表
      // 获取全部角色
      this.roleDataList = []
      this.roleInfoList = []
      let { data } = await commonAllRole({companyId: -1})
      data.forEach(element => {
        this.roleDataList.push(element.id)
        this.roleInfoList.push({id: element.id,name: element.name})
      });

    },
    async getTemplateRole(ids,name,index) { // 获取模板角色列表
      let { data } = await getTemplateRole({ids})
      if(name == 'send'){
        this.sendList = data
      }
      if(name == 'head'){
        this.headList = data
      }
      if(name == 'list'){
        // this.hanObj[index] = data
        this.$set(this.hanObj,index,data)
      }
    },
    async getDetails(){
      this.loading = true
      const { data } = await selectWorkOrder({id: this.$route.query.id,companyId: this.comId})

      this.isUseCompany = data.isUseCompany

      this.params.workTypeName = data.workTypeName
      this.params.typeDescribe = data.typeDescribe
      this.params.workTypeId = data.workTypeId
      this.params.sendType = data.sendType
      this.params.sendId = data.sendId
      this.params.headType = data.headType
      this.params.headId = data.headId

      
      if (data.workOrderFlowDTOList&&data.workOrderFlowDTOList.length){ //流程
        data.workOrderFlowDTOList.forEach((element,index) => {
          let fileIdList=[];//附件Id
          let file = [];
          // 处理人
          if(element.approveType == 3){
            let han = []
            han.push({name: '发起人自选'})
            this.hanObj[index] = han
          } else if(element.approveType == 2){ //指定人
            // 1. 处理人
            let han = []
            let hanIdList = []
            let hanNameList= []
            hanIdList = element.approveId.split(",")
            hanNameList = element.approveName.split(",")
            if(hanIdList.length){
              hanIdList.forEach((item,index)=>{
                han.push({id: item,name: hanNameList[index]})
              })
            }
            this.hanObj[index] = han
          } else{
            this.getTemplateRole(element.approveId,'list',index)
          }

          if(element.workOrderFileDTOList&&element.workOrderFileDTOList.length){//文件列表
            element.workOrderFileDTOList.forEach((item) => {
              fileIdList.push(item.fileId) //params参数
              file.push({'id': item.fileId,'name': item.fileName,'url': item.filedUrl}) //展示附件
              this.fileObj[index] = file//展示附件
            })
          }
          this.params.workOrderFlowDTOList.push({taskName: element.taskName,taskDescribe: element.taskDescribe,approveType: element.approveType,approveId: element.approveId,fileIds:fileIdList,fileList: file,sort: element.sort})
        });
      }
      
      if(data.sendType == 3){
            let send = []
            send.push({name: '发起人自选'})
            this.sendList = send
      } else if(data.sendType == 2) { //指定人
        // 2.抄送人
        if(data.sendId) {
          let sendIdList = []
          let sendNameList= []
          sendIdList = data.sendId.split(",")
          sendNameList = data.sendName.split(",")
          if(sendIdList.length){
            sendIdList.forEach((item,index)=>{
              this.sendList.push({id: item,name: sendNameList[index]})
            })
          }
        }
      } else{
        this.getTemplateRole(data.sendId,'send')
      }


      if(data.headType == 3){
            let head = []
            head.push({name: '发起人自选'})
            this.headList = head
      } else if(data.headType == 2) { //指定人
        // 3.负责人
        if(data.headId) {
          let headIdList = []
          let headNameList= []
          headIdList = data.headId.split(",")
          headNameList = data.headName.split(",")
          if(headIdList.length){
            headIdList.forEach((item,index)=>{
              this.headList.push({id: item,name: headNameList[index]})
            })
          }
        }
      } else{
        this.getTemplateRole(data.headId,'head')
      }
      
      this.loading = false
    },
    // 保存编辑
    async editSaveWork(){
      // 处理文件
      let params = JSON.parse(JSON.stringify(this.params));
      params.workOrderFlowDTOList.forEach(ele=>{
        if(typeof(ele.fileIds) == 'object'){
          if(ele.fileIds&&ele.fileIds.length){
            ele.fileIds = ele.fileIds.join(',')
          }else{
            ele.fileIds = ""
          }
        }else if(typeof(ele.fileIds) == 'string'){ //字符串
          ele.fileIds
        }
        delete ele.fileList
      })
      // 提醒
      if(!params.workTypeName){
        return this.$message.warning("请输入类型名称")
      }
      if (!params.typeDescribe){
        return this.$message.warning("请输入类型描述")
      }
      if (params.workOrderFlowDTOList&&params.workOrderFlowDTOList.length){
        let isNull = false
        params.workOrderFlowDTOList.forEach(ele => {
          if(!ele.taskName){
            isNull = true
            // return this.$message.error("请输入任务名称")
          }
          // if(!ele.taskDescribe){
          //   isNull = true
          //   // return this.$message.error("请输入任务描述")
          // }
          if(!ele.approveType){
            isNull = true
            // return this.$message.error("请选择处理人")
          }
          // if(!ele.fileIds){
          //   isNull = true
          //   // return this.$message.error("请添加附件")
          // }
        })
        if (isNull) {
          // setTimeout(()=>{
            return this.$message.warning("请先进行设置任务名称和处理人")
          // },10)
        }
      }
      if (!params.sendType){
        return this.$message.warning("请选择抄送人")
      }
      if (!params.headType){
        return this.$message.warning("请选择负责人")
      }
      params.companyId = this.comId
      const res = await updateWorkOrder(params)
      if(res.code == 200){
        this.$message.success("编辑成功")
        this.$router.push('/work-order/setting')
      }
    },
    addList(){// +按钮
      this.params.workOrderFlowDTOList.push({taskName: '',taskDescribe: '',fileIds: [],fileList: [],approveType: '',approveId: '',sort: ''})
    },
    colletRoleId(val){
        let roleList = [];
        if(val&&val.length){
          val.forEach(item => {
            roleList.push(item.id)
          })
        }
        if(this.type == 'one'){ //抄送人
          if(this.radio == 1){
            this.sendList = val
            this.params.sendId = roleList.join(',')
            this.params.sendType = 1
          } else if(this.radio == 3){ //指定人员
            this.sendList = val;
            this.params.sendId = roleList.join(",");
            this.params.sendType = 2;
          } else{
            this.sendList = [{name: '发起人自选'}];
            // this.sendList = this.roleInfoList
            // this.params.sendId = this.roleDataList.join(',')
            this.params.sendType = 3
          }
        }else if(this.type == 'two'){ //负责人
          if(this.radio == 1){
            this.headList = val
            this.params.headId = roleList.join(',')
            this.params.headType = 1
          } else if(this.radio == 3){ //指定人员
            this.headList = val;
            this.params.headId = roleList.join(",");
            this.params.headType = 2;
          } else{
            this.headList = [{name: '发起人自选'}];
            // this.headList = this.roleInfoList
            // this.params.headId = this.roleDataList.join(',')
            this.params.headType = 3
          }
        }else{ // 流程
          if(this.radio == 1){
            this.params.workOrderFlowDTOList[this.armNum].approveId = roleList.join(',')//参数
            this.hanObj[this.armNum] = val //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 1
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1
          } else if(this.radio == 3){ //指定人员
            this.params.workOrderFlowDTOList[
              this.armNum
            ].approveId = roleList.join(","); //参数
            this.hanObj[this.armNum] = val; //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 2;
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1;
          } else{
            // this.params.workOrderFlowDTOList[this.armNum].approveId = this.roleDataList.join(',')
            // this.hanObj[this.armNum] = this.roleInfoList //用于处理人遍历数据
            this.hanObj[this.armNum] = [{name: '发起人自选'}]; //用于处理人遍历数据
            this.params.workOrderFlowDTOList[this.armNum].approveType = 3
            this.params.workOrderFlowDTOList[this.armNum].sort = this.armNum + 1
          }
          
        }
        this.type = ""
    },
    roleClose(){
      this.isPeople = false;
      this.handlerVisible = false
      this.roleVisible = false
      this.oneRole =""
      this.type = ""
    },
    sortUp(item,i){
      if(i == 0){
        this.$message.warning("已经是第一个啦")
      }else{
        this.params.workOrderFlowDTOList[i].sort = this.params.workOrderFlowDTOList[i-1].sort
        this.params.workOrderFlowDTOList[i-1].sort = this.params.workOrderFlowDTOList[i].sort
        let workList = this.params.workOrderFlowDTOList
        let temp = workList[i - 1] //上一条数据 
        this.$set(workList, i - 1, workList[i])
        this.$set(workList, i, temp)

        // 附件
        let file1 = this.fileObj[i]
        let file2 = this.fileObj[i-1]
        this.$set(this.fileObj, i - 1, file1)
        this.$set(this.fileObj, i, file2)
        // 处理人
        let han1 = this.hanObj[i]
        let han2 = this.hanObj[i-1]
        this.$set(this.hanObj, i - 1, han1)
        this.$set(this.hanObj, i, han2)
      }
    },
    // 下
    sortDown(item,i){
      let workList = this.params.workOrderFlowDTOList
      if (i == (workList.length - 1)) {
        this.$message({
          message: '已经是最后一个啦',
          type: 'warning'
        })
      } else {
        this.params.workOrderFlowDTOList[i].sort = this.params.workOrderFlowDTOList[i].sort + 1
        this.params.workOrderFlowDTOList[i+1].sort = this.params.workOrderFlowDTOList[i+1].sort - 1
        let temp = workList[i + 1]//下一条数据 
        this.$set(workList, i + 1, workList[i])
        this.$set(workList, i, temp)

        // 附件
        let file1 = this.fileObj[i]
        let file2 = this.fileObj[i+1]
        this.$set(this.fileObj, i + 1, file1)
        this.$set(this.fileObj, i, file2)
        // 处理人
        let han1 = this.hanObj[i]
        let han2 = this.hanObj[i+1]
        this.$set(this.hanObj, i + 1, han1)
        this.$set(this.hanObj, i, han2)
      }
    },
    deleteStep(item,i){
      let workList = this.params.workOrderFlowDTOList
      if(i||i==0){
        if(i != workList.length-1){
          workList[i+1].sort = workList[i+1].sort - 1
        }
      }
      workList = workList.filter((ele,index)=> {
        return index != i
      })
      // 附件
        let file1 = this.fileObj[i]
        let file2 = this.fileObj[i+1]
        this.$set(this.fileObj, i + 1, file1)
        this.$set(this.fileObj, i, file2)
        // 处理人
        let han1 = this.hanObj[i]
        let han2 = this.hanObj[i+1]
        this.$set(this.hanObj, i + 1, han1)
        this.$set(this.hanObj, i, han2)
      this.params.workOrderFlowDTOList = workList
    },
    chooseRoles(){
      if(this.type == 'one'){
        this.roleVisible = true;
        this.oneRole = 'one'
        this.showRoleId = this.params.sendId
        this.$refs.choRole.showRole(this.showRoleId,"one")
      }else if(this.type == 'two'){
        this.roleVisible = true;
        this.showRoleId = this.params.headId
        this.$refs.choRole.showRole(this.showRoleId)
      }else{
        this.roleVisible = true;
        this.showRoleId = this.params.workOrderFlowDTOList[this.armNum].approveId
        this.$refs.choRole.showRole(this.showRoleId)
      }
      
    },
    choosePeople(){ //选择指定人员
        this.roleVisible = true;
        this.isPeople = true
      if(this.type == 'one'){
        if(this.params.sendType=='1'){
          this.showRoleId = ""
        }else {
          this.showRoleId = this.params.sendId;
        }
        this.$refs.choRole.showRole(this.showRoleId,"",JSON.stringify(this.sendList));
      } else if(this.type == 'two') {
        if(this.params.headType=='1'){
          this.showRoleId = ""
        }else {
          this.showRoleId = this.params.headId;
        }
        this.$refs.choRole.showRole(this.showRoleId,'',JSON.stringify(this.headList));
      } else {
        if(this.params.workOrderFlowDTOList[this.armNum].approveType=='1'){ //角色 ==> 指定人
          this.showRoleId = ""
        }else {
          this.showRoleId = this.params.workOrderFlowDTOList[this.armNum].approveId;
        }

        this.$refs.choRole.showRole(this.showRoleId,"",JSON.stringify(this.hanObj[this.armNum]));
      }
    },
    async uploadFile(e,index) {
      // 上传合同
      this.loading = true
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.loading = false
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = fileName.split(".")[fileName.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading = false
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      console.log(this.params.workOrderFlowDTOList[index].fileIds,'**************');
      this.params.workOrderFlowDTOList[index].fileIds.push(resData.id)
      
      // 暂存
      this.params.workOrderFlowDTOList[index].fileList.push({url: resData.filename,name: fileName,id: resData.id})
      this.fileObj[index] = [...this.params.workOrderFlowDTOList[index].fileList]
      e.target.value = "";
      this.loading = false
    },
    // 删除附件
    deleteFile(id,i,it2Index) {
      this.params.workOrderFlowDTOList[it2Index].fileList = this.params.workOrderFlowDTOList[it2Index].fileList.filter((item, index) => { return it2Index != index })
      this.fileObj[i].splice(it2Index,1)
      this.$set(this.fileObj,i,this.fileObj[i])
      console.log(this.fileObj[i])
      let a = this.params.workOrderFlowDTOList[i].fileIds
      let b = a.splice(it2Index,1)
      console.log(a,b,'a-----b');
      // this.params.workOrderFlowDTOList[i].fileIds = a.join(",")
      this.params.workOrderFlowDTOList[i].fileIds = a
      this.$forceUpdate()
    },
    download(url) { // 下载
      window.location.href = url
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px 32px;
  text-align: left;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-tit {
      font-size: 20px;
      color: #101010;
    }
    .btn {
      width: 101px;
    }
  }
  .line {
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  .body{
    text-align: left;
    margin-top: 44px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
    .calname{
      margin-bottom: 24px;
    }
    /deep/.common-screen-input{
        .el-input__inner{
          padding-right: 60px;
        }
        .el-input__count-inner{
          font-size: 12px;
        }
      }
  }
  .pro-container {
    .set {
      display: inline-block;
      margin: 24px 0;
    }
    /deep/.el-step {
      .el-step__main{
        margin-bottom: 43px;
        margin-left: 20px;
      }
    }
    /deep/ .everystep{
      .el-step__main{
        padding: 22px 10px;
        border: 1px solid #1890ff;
        border-radius: 4px;
      }
    }
    /deep/ .el-step__title{
          color: #333;
    }
    /deep/.add{
      .el-step__main{
        margin-bottom: 63px;
      }
    }
    /deep/ .el-step__title{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    /deep/ .el-step__icon{
      border: 3px solid #EFF5FF;
      background-color: #1890ff;
      color: #fff;
      .el-step__icon-inner{
        color: #fff;
      }
    }
    /deep/ .is-plain{
      border: 1px solid #1890ff;
      i{
        color: #1890ff;
      }
      span{
        color: #1890ff;
      }
    }
    .process {
      border: 1px solid #d9d9d9;
      padding: 42px 28px;
      .box {
        display: inline-block;
        height: 44px;
        line-height: 1.73;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        overflow: hidden;
        .black {
          display: inline-block;
          width: 104px;
          text-align: center;
          box-sizing: border-box;
          padding: 10px 16px;
          background-color: #ebebeb;
          color: #333;
          font-size: 14px;
          font-weight: 400;
        }
        .white {
          display: inline-block;
          // padding: 10px 98px 10px 16px;
          color: #333;
          font-size: 14px;
          font-weight: 400;
          /deep/ .el-input__inner {
            height: 45px;
            border: none;
            padding-right: 60px;
          }
          /deep/.el-input__count-inner{
            font-size: 12px;
          }
        }
        .person {
          display: inline-block;
          padding: 0 12px;
          div {
            display: inline-block;
          }
          .ever {
            display: inline-block;
            padding: 4px 12px;
            color: #fff;
            background-color: #1890ff;
            border-radius: 4px;
            &:not(:first-child) {
              margin-left: 8px;
            }
          }
          .btn {
            display: inline-block;
            margin-left: 50px;
          }
        }
      }
      .des {
        margin-top: 24px;
        width: 706px;
        .desTit {
          box-sizing: border-box;
          padding: 10px 16px;
          border: 1px solid #d9d9d9;
          border-radius: 4px 4px 0 0;
          border-bottom: none;
          height: 40px;
          color: #333;
          background-color: #eaebea;
        }
        /deep/.el-textarea__inner {
          border-radius: 0 0 4px 4px;
        }
      }
      .handler {
        margin: 24px 0 0 0;
      }
      /deep/ .el-step__description {
        padding-right: 0;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          margin-top: 12px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 350px;
            .fileItem-btn-Container {
              display: flex;
              align-items: center;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #dcdfe6;
            color: #666;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            // margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      /deep/ .is-circle {
        position: relative;
        width: 24px;
        height: 24px;
        .el-icon-circle-plus-outline {
          position: absolute;
          top: -3px;
          left: -3px;
          font-size: 30px;
          color: #1890ff;
        }
      }
      /deep/ .down{
        margin-right: 10px;
      }
    }
  }
}
</style>
